import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Gallery from '../components/Gallery';
import Button from '../components/Button';
import ChevronDown from '../svg/icons/chevron-down.svg';

import {
  hero,
  logo,
  iconDown,
  about,
  information,
} from '../css/Home.module.css';

const Home = ({ data }) => {
  const { wpWebdata } = data;
  const attributes = wpWebdata;

  return (
    <Layout pageTitle={attributes.shibuya.titleHome} copyright={attributes.shibuya.copyright}>
      <section className={hero}>
        <Header attributes={attributes} />
        <StaticImage src="../images/logo.png" alt="Shibuya" placeholder="transparent" className={logo} />
        <ChevronDown className={iconDown} />
      </section>
      <article className={about}>
        <h1>{attributes.shibuya.aboutUs}</h1>
        <p>{attributes.shibuya.aboutUsLine1}</p>
        <p>{attributes.shibuya.aboutUsLine2}</p>
        <p>{attributes.shibuya.aboutUsLine3}</p>
        <p>{attributes.shibuya.aboutUsLine4}</p>
        <p>{attributes.shibuya.aboutUsLine5}</p>
      </article>
      <article className={information}>
        <h1>{attributes.shibuya.information}</h1>
        <h3>{attributes.shibuya.payment}</h3>
        <p>{attributes.shibuya.paymentLine1}</p>
        <h3>{attributes.shibuya.timeslots}</h3>
        <ul>
          <li>{attributes.shibuya.timeslot1}</li>
          <li>{attributes.shibuya.timeslot2}</li>
          <li>{attributes.shibuya.timeslot3}</li>
          <li>{attributes.shibuya.timeslot4}</li>
        </ul>
        <p>{attributes.shibuya.informationLine1}</p>
        <p>{attributes.shibuya.informationLine2}</p>
        <p>{attributes.shibuya.informationLine3}</p>
      </article>
      <article className={information}>
        <h1>{attributes.shibuya.titleSonglist}</h1>
        <p>{attributes.shibuya.songlistText}</p>
        <img src={attributes.shibuya.songlistQrImage.node.sourceUrl} alt="QR code" />
        <a href={attributes.shibuya.songlistButtonLink} target="_blank" rel="noopener noreferrer">
          <Button labelText={attributes.shibuya.songlistButtonText} />
        </a>
      </article>
      <Gallery itemsToShow={4} attributes={attributes} />
      <Link to="/gallery">
        <Button labelText={attributes.shibuya.viewGallery} />
      </Link>
    </Layout>
  );
};

export default Home;

Home.propTypes = {
  data: PropTypes.shape({
    wpWebdata: PropTypes.shape({
      title: PropTypes.string,
      shibuya: PropTypes.shape({
        titleHome: PropTypes.string,
        titleAboutUs: PropTypes.string,
        titleMenu: PropTypes.string,
        titleReserve: PropTypes.string,
        titleFaq: PropTypes.string,
        titleContact: PropTypes.string,
        aboutUs: PropTypes.string,
        aboutUsLine1: PropTypes.string,
        aboutUsLine2: PropTypes.string,
        aboutUsLine3: PropTypes.string,
        aboutUsLine4: PropTypes.string,
        aboutUsLine5: PropTypes.string,
        information: PropTypes.string,
        informationLine1: PropTypes.string,
        informationLine2: PropTypes.string,
        informationLine3: PropTypes.string,
        titleSonglist: PropTypes.string,
        songlistText: PropTypes.string,
        songlistQrImage: PropTypes.shape({
          node: PropTypes.shape({
            sourceUrl: PropTypes.string,
          }),
        }),
        songlistButtonText: PropTypes.string,
        songlistButtonLink: PropTypes.string,
        payment: PropTypes.string,
        paymentLine1: PropTypes.string,
        timeslots: PropTypes.string,
        timeslot1: PropTypes.string,
        timeslot2: PropTypes.string,
        timeslot3: PropTypes.string,
        timeslot4: PropTypes.string,
        viewGallery: PropTypes.string,
        copyright: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
query ($language: String!) {
  wpWebdata(title: {eq: $language}) {
    title
    shibuya {
      titleHome
      titleAboutUs
      titleMenu
      titleReserve
      titleFaq
      titleContact
      aboutUs
      aboutUsLine1
      aboutUsLine2
      aboutUsLine3
      aboutUsLine4
      aboutUsLine5
      information
      informationLine1
      informationLine2
      informationLine3
      titleSonglist
      songlistText
      songlistQrImage {
        node {
          sourceUrl
        }
      }
      songlistButtonText
      songlistButtonLink
      payment
      paymentLine1
      timeslots
      timeslot1
      timeslot2
      timeslot3
      timeslot4
      viewGallery
      copyright
    }
  }
}
`;
