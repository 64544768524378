import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import slice from 'lodash/slice';
import size from 'lodash/size';

import gallery1 from '../images/IMG_4994.jpg';
import gallery2 from '../images/IMG_4995.jpg';
import gallery3 from '../images/IMG_4997.jpg';
import gallery4 from '../images/IMG_4998.jpg';
import gallery5 from '../images/IMG_4999.jpg';

import {
  container,
  images,
  imageContainer,
  image,
  spacer,
} from './Gallery.module.css';

const propTypes = {
  itemsToShow: PropTypes.number,
  attributes: PropTypes.shape({
    shibuya: PropTypes.shape({
      gallery: PropTypes.string,
    }),
  }).isRequired,
};

const data = [
  {
    src: gallery1,
    alt: 'gallery-1',
    title: 'gallery-1',
    width: 1600,
    height: 900,
  },
  {
    src: gallery2,
    alt: 'gallery-2',
    title: 'gallery-2',
    width: 1600,
    height: 900,
  },
  {
    src: gallery3,
    alt: 'gallery-3',
    title: 'gallery-3',
    width: 1600,
    height: 900,
  },
  {
    src: gallery4,
    alt: 'gallery-4',
    title: 'gallery-4',
    width: 1600,
    height: 900,
  },
  {
    src: gallery5,
    alt: 'gallery-5',
    title: 'gallery-5',
    width: 1600,
    height: 900,
  },
];

const Gallery = (props) => {
  const { itemsToShow = size(data), attributes } = props;

  return (
    <section className={container}>
      <h1>{attributes.shibuya.gallery}</h1>
      <section className={images}>
        {map(slice(data, 0, itemsToShow), (item) => (
          <div
            className={imageContainer}
            key={item.title}
          >
            <img src={item.src} alt={item.alt} className={image} />
          </div>
        ))}
        <div className={spacer} />
      </section>
    </section>
  );
};

Gallery.propTypes = propTypes;

export default Gallery;
